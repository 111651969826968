import {AuthActions, isUserAuthenticated} from '../../authentication-api'
import {FileUploadActions, ViewState} from '../../file-upload-api'
import {WorkspaceRoutes, isExternalChinaMode, isExternalMode} from '../../shell-api'
import {useDispatch, useSelector} from 'react-redux'

import {ApplicationGlobalState} from '../../system-api'
import {LayoutView} from './layoutView'
import React from 'react'
import {RoutingActions} from '@uieng/polimer-js-routing'
import {isInternalChinaMode} from '../../shell-api'
import {useActionCallback} from '@uieng/polimer-js'

interface StoreProps {
   version: string
   userIPAddress: string
   uploadManagerButtonVisible: boolean
   changePasswordButtonVisible: boolean
   showSessionButtonVisible: boolean
   userProfileButtonVisible: boolean
   logoutButtonVisible: boolean
}

const RenderLayoutContainer: React.FC = () => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((state) => ({
      version: state.shell.config?.version ?? '',
      userIPAddress: state.shell.userIP,
      uploadManagerButtonVisible: isUserAuthenticated(state.auth),
      changePasswordButtonVisible: true,
      showSessionButtonVisible: true,
      userProfileButtonVisible: isExternalMode() || isExternalChinaMode(),
      logoutButtonVisible: isUserAuthenticated(state.auth) && !isInternalChinaMode(),
   }))

   const dispatch = useDispatch()

   const onOpenUploadManager = useActionCallback(() => FileUploadActions.setViewState(ViewState.Maximised), dispatch)
   const onResetPasswordClick = useActionCallback(
      () => RoutingActions.navigateToRoute(WorkspaceRoutes.ResetPassword),
      dispatch,
   )
   const onUserProfileClick = useActionCallback(
      () => RoutingActions.navigateToRoute(WorkspaceRoutes.UserProfile),
      dispatch,
   )
   const onLogoutClick = useActionCallback(() => AuthActions.logout(), dispatch)
   const onShowSessionClick = useActionCallback(
      () => RoutingActions.navigateToRoute(WorkspaceRoutes.ShowSession),
      dispatch,
   )

   return (
      <LayoutView
         version={storeProps.version}
         userIPAddress={storeProps.userIPAddress}
         uploadManagerButtonVisible={storeProps.uploadManagerButtonVisible}
         changePasswordButtonVisible={storeProps.changePasswordButtonVisible}
         showSessionButtonVisible={storeProps.showSessionButtonVisible}
         userProfileButtonVisible={storeProps.userProfileButtonVisible}
         logoutButtonVisible={storeProps.logoutButtonVisible}
         onOpenUploadManager={onOpenUploadManager}
         onChangePasswordClick={onResetPasswordClick}
         onShowSessionClick={onShowSessionClick}
         onUserProfileClick={onUserProfileClick}
         onLogoutClick={onLogoutClick}
      />
   )
}

export const LayoutContainer = React.memo(RenderLayoutContainer)
