import {ModuleApi} from '@uieng/polimer-js'
import {WorkspaceRoutes} from '../shell-api'
import {ApplicationGlobalState} from '../system-api'
import {sessionEpic} from './epics'
import {sessionReducer} from './reducers'
import {GetSessionState} from '../session-api'
import {SessionContainer} from './view/sessionContainer'

export const sessionModuleApi: ModuleApi<ApplicationGlobalState, GetSessionState> = {
   moduleId: '',
   moduleKey: 'session',
   reducer: sessionReducer,
   epic: sessionEpic,
   defaultViewContainer: SessionContainer,
   routingConfig: {
      routeName: WorkspaceRoutes.ShowSession,
      route: '/session',
      loadOnStartup: true,
   },
}
