import {Header, HeaderBrand, HeaderNavigation, HeaderProfileLink, HeaderUtilities} from '@gs-ux-uitoolkit-react/header'
import React, {useMemo} from 'react'

import {FooterView} from './footerView'
import {WorkspaceContainer} from './workspace/workspaceContainer'
import styles from './styles.module.scss'

const brand: HeaderBrand = {
   appName: 'SFX - Secure File Xchange',
   callback: () => window.location.reload(),
}

interface Props {
   version: string
   userIPAddress: string
   uploadManagerButtonVisible: boolean
   changePasswordButtonVisible: boolean
   showSessionButtonVisible: boolean
   userProfileButtonVisible: boolean
   logoutButtonVisible: boolean

   onOpenUploadManager: () => void
   onChangePasswordClick: () => void
   onShowSessionClick: () => void
   onUserProfileClick: () => void
   onLogoutClick: () => void
}

const RenderLayoutView: React.FC<Props> = (props) => {
   const {
      userIPAddress,
      changePasswordButtonVisible,
      showSessionButtonVisible,
      uploadManagerButtonVisible,
      userProfileButtonVisible,
      logoutButtonVisible,
      onOpenUploadManager,
      onChangePasswordClick,
      onShowSessionClick,
      onUserProfileClick,
      onLogoutClick,
   } = props

   const navigation: HeaderNavigation = useMemo(() => {
      if (uploadManagerButtonVisible === true) {
         return {
            CTAButton: {
               iconName: 'upload',
               buttonText: 'Upload Manager',
               callback: () => onOpenUploadManager(),
            },
         }
      }

      return {}
   }, [uploadManagerButtonVisible, onOpenUploadManager])

   const utilities: HeaderUtilities = useMemo(() => {
      const profileLinks: HeaderProfileLink[] = []

      if (userProfileButtonVisible === true) {
         profileLinks.push({
            id: 'user-profile-button',
            icon: 'user',
            text: 'Profile',
            callback: () => onUserProfileClick(),
         })
      }

      if (changePasswordButtonVisible === true) {
         profileLinks.push({
            id: 'change-password-button',
            icon: 'lock',
            text: 'Change Password',
            callback: () => onChangePasswordClick(),
         })
      }

      if (showSessionButtonVisible === true) {
         profileLinks.push({
            id: 'show-session-button',
            text: 'Active sessions',
            callback: () => onShowSessionClick(),
         })
      }

      if (logoutButtonVisible === true) {
         profileLinks.push({
            id: 'logout-button',
            icon: 'mq-log-out',
            text: 'Logout',
            callback: () => onLogoutClick(),
         })
      }

      return {
         profile: {
            identity: {
               personMenuIcon: false,
               secondaryInfo: 'Your IP: ' + userIPAddress,
            },
            links: profileLinks,
         },
      }
   }, [
      userIPAddress,
      changePasswordButtonVisible,
      showSessionButtonVisible,
      userProfileButtonVisible,
      logoutButtonVisible,
      onChangePasswordClick,
      onShowSessionClick,
      onUserProfileClick,
      onLogoutClick,
   ])

   return (
      <div className={styles.layoutContainer}>
         <Header brand={brand} navigation={navigation} utilities={utilities} />

         <WorkspaceContainer className={styles.workspaceContainer} />

         <FooterView version={props.version} />
      </div>
   )
}

export const LayoutView = React.memo(RenderLayoutView)
