import {GetProfileDetailsResponseDto, GetSessionResponseDto} from '../../../messaging-generated-code'
import {GetProfileDetailsResponse, GetSessionResponse, Session} from '../../userProfileService'

export const getProfileDetailsResponseMapper = (dto: GetProfileDetailsResponseDto): GetProfileDetailsResponse => {
   return {
      accountName: dto.accountName,
      contactEmail: dto.externalContactEmail,
      contactName: dto.externalContactName,
   }
}

export const getSessionResponseWrapper = (dto: GetSessionResponseDto): GetSessionResponse => {
   const sessionData: Session[] = JSON.parse(JSON.stringify(dto))
   return {sessions: sessionData}
}
