import {getLogger} from '@uieng/logger'
import {requestResponseWrapper, ResponseWrapper} from '@uieng/messaging-api'
import {Observable} from 'rxjs'
import {map, tap} from 'rxjs/operators'
import {UserProfileReactiveClient} from '../messaging-generated-code'
import {changePasswordRequestMapper} from './mappers/request/changePasswordRequestMapper'
import {updateProfileDetailsRequestMapper} from './mappers/request/updateProfileDetailsRequestMapper'
import {
   getProfileDetailsResponseMapper,
   getSessionResponseWrapper,
} from './mappers/response/getProfileDetailsResponseMapper'

const logger = getLogger('DefaultUserProfileService')

export interface GetSessionResponse {
   sessions: Session[]
}

export interface Session {
   ip: string
   expiresAt: string
   isCurrentSession: boolean
}

export interface ChangePasswordRequest {
   username: string
   currentPassword: string
   newPassword: string
}

export interface ChangePasswordResponse {}

export interface GetProfileDetailsResponse {
   accountName: string
   contactName: string
   contactEmail: string
}

export interface UpdateProfileDetailsRequest {
   accountName: string
   contactName: string
   contactEmail: string
}

export interface UpdateProfileDetailsResponse {}

export interface UserProfileService {
   getIPAddress(): Observable<ResponseWrapper<void, string>>

   changePassword(
      request: ChangePasswordRequest,
   ): Observable<ResponseWrapper<ChangePasswordRequest, ChangePasswordResponse>>

   getProfileDetails(): Observable<ResponseWrapper<void, GetProfileDetailsResponse>>

   updateProfileDetails(
      request: UpdateProfileDetailsRequest,
   ): Observable<ResponseWrapper<UpdateProfileDetailsRequest, UpdateProfileDetailsResponse>>

   getSessions(): Observable<ResponseWrapper<void, GetSessionResponse>>
}

export class DefaultUserProfileService implements UserProfileService {
   constructor(private readonly _client: UserProfileReactiveClient) {}

   getIPAddress(): Observable<ResponseWrapper<void, string>> {
      logger.debug('About to send getIPAddress request')

      return this._client.getIPAddress().pipe(
         requestResponseWrapper(void 0),
         tap(({error}) => {
            if (error != null) {
               logger.warn('getIPAddress request failed', error)
            }
         }),
      )
   }

   changePassword(
      request: ChangePasswordRequest,
   ): Observable<ResponseWrapper<ChangePasswordRequest, ChangePasswordResponse>> {
      logger.debug('About to send changePassword request')

      return this._client.changePassword(changePasswordRequestMapper(request)).pipe(
         requestResponseWrapper(request),
         tap(({error}) => {
            if (error != null) {
               logger.warn('changePassword request failed', error)
            }
         }),
      )
   }

   getProfileDetails(): Observable<ResponseWrapper<void, GetProfileDetailsResponse>> {
      logger.debug('About to send getProfileDetails request')

      return this._client.getProfileDetails().pipe(
         map(getProfileDetailsResponseMapper),
         requestResponseWrapper(void 0),
         tap(({error}) => {
            if (error != null) {
               logger.warn('getProfileDetails request failed', error)
            }
         }),
      )
   }

   updateProfileDetails(
      request: UpdateProfileDetailsRequest,
   ): Observable<ResponseWrapper<UpdateProfileDetailsRequest, UpdateProfileDetailsResponse>> {
      logger.debug('About to send updateProfileDetails request')

      return this._client.updateProfileDetails(updateProfileDetailsRequestMapper(request)).pipe(
         requestResponseWrapper(request),
         tap(({error}) => {
            if (error != null) {
               logger.warn('updateProfileDetails request failed', error)
            }
         }),
      )
   }

   getSessions(): Observable<ResponseWrapper<void, GetSessionResponse>> {
      logger.debug('About to send getSessions request for user')
      return this._client.getSession().pipe(
         map(getSessionResponseWrapper),
         requestResponseWrapper(void 0),
         tap(({error}) => {
            if (error != null) {
               logger.warn('getSessions request failed', error)
            }
         }),
      )
   }
}
