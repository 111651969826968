import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {ResponseWrapper} from '@uieng/messaging-api'
import {GetSessionResponse} from '../services'

export enum SessionActionTypes {
   GET_SESSION = '[session] GET_SESSION',
   GET_SESSION_RESULT = '[session] GET_SESSION_RESULT',
}

export const SessionActions = {
   getSession: () => createAction(SessionActionTypes.GET_SESSION),
   getSessionResult: (result: ResponseWrapper<void, GetSessionResponse>) =>
      createAction(SessionActionTypes.GET_SESSION_RESULT, result),
}

export type SessionActions = ActionsUnion<typeof SessionActions>
