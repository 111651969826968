import {ofType} from '@martin_hotell/rex-tils'
import {combineEpics} from 'redux-observable'
import {flatMap, map} from 'rxjs/operators'
import {ApplicationEpic} from '../../system-api'
import {SessionActionTypes, SessionActions} from '../../session-api'

const doGetSessionsEpic: ApplicationEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(SessionActionTypes.GET_SESSION),
      flatMap(() => {
         return deps.userProfileService.getSessions()
      }),
      map(SessionActions.getSessionResult),
   )

export const getSessionsEpic = combineEpics(doGetSessionsEpic)
