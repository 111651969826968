import {ResponseDefinition} from '@uieng/messaging-api'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ApplicationGlobalState} from '../../system-api'
import {GetSessionResponse} from '../../services'
import {SessionActions} from '../../session-api'
import {SessionView} from './sessionView'

interface StoreProps {
   response: ResponseDefinition<GetSessionResponse>
}

const RenderSessionContainer: React.FC = () => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((store) => ({
      response: store.session.getSessionResponse,
   }))

   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(SessionActions.getSession())
   }, [dispatch])

   return <SessionView sessions={storeProps.response.data?.sessions ?? []}></SessionView>
}

export const SessionContainer = React.memo(RenderSessionContainer)
