import {createMutableReducer} from '@uieng/common'
import {ResponseState} from '@uieng/messaging-api'
import {AllActions, AllActionTypes} from '../../system-api'
import {GetSessionState, SessionActionTypes} from '../../session-api'

const initialState: GetSessionState = {
   getSessionResponse: {
      state: ResponseState.Idle,
   },
}

export const sessionReducer = createMutableReducer<AllActionTypes, AllActions, GetSessionState>(
   {
      [SessionActionTypes.GET_SESSION]: (draft) => {
         draft.getSessionResponse.state = ResponseState.Loading

         delete draft.getSessionResponse.data
      },
      [SessionActionTypes.GET_SESSION_RESULT]: (draft, action) => {
         const result = action.payload

         delete draft.getSessionResponse.data

         if (result.error != null) {
            draft.getSessionResponse.state = ResponseState.Error
         } else if (result.response != null) {
            draft.getSessionResponse.state = ResponseState.Success
            draft.getSessionResponse.data = {sessions: result.response?.sessions ?? []}
         }
      },
   },
   initialState,
)
