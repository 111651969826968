import {Col, Container, Row} from '@gs-ux-uitoolkit-react/core'
import React from 'react'

import cn from 'classnames'
import styles from './styles.module.scss'
import {Session} from '../../services'

interface Props {
   sessions: Session[]
}

const RenderSessionView: React.FC<Props> = (props) => {
   const {sessions} = props

   // Function to convert RFC3339 to a human-readable format
   const formatTime = (timeString: string) => {
      const date = new Date(timeString)
      return date.toString()
   }

   return (
      <Container className={styles.rootContainer}>
         <Row className="justify-content-center">
            <Col sm="6" className={cn('gs-uitk-shadow-100', styles.contentPanel)}>
               <h4> Active sessions</h4>
               {sessions.map((session, index) => {
                  return (
                     <div key={index}>
                        {session.isCurrentSession && <b>Current session</b>}
                        <p>IP: {session.ip}</p>
                        <p>Expires at: {formatTime(session.expiresAt)}</p>
                        <br />
                     </div>
                  )
               })}

               {sessions.length === 0 && <p>No active sessions</p>}
            </Col>
         </Row>
      </Container>
   )
}

export const SessionView = React.memo(RenderSessionView)
