import {RoutingConfig} from '@uieng/polimer-js-routing'
import {WorkspaceRoutes} from './modules/shell-api'
import {authenticationModuleApi} from './modules/authentication'
import {directoryListModuleApi} from './modules/directory-list'
import {fileUploadModuleApi} from './modules/file-upload'
import {resetPasswordModuleApi} from './modules/reset-password'
import {unauthorizedModuleApi} from './modules/unauthorized'
import {userProfileModuleApi} from './modules/user-profile'
import {sessionModuleApi} from './modules/session'

export function getRoutingConfiguration(): RoutingConfig {
   return {
      pathPrefix: '/',
      defaultRouteName: WorkspaceRoutes.Login,
      mapping: {
         [WorkspaceRoutes.Login]: {
            moduleApi: authenticationModuleApi,
         },
         [WorkspaceRoutes.DirectoryList]: {
            moduleApi: directoryListModuleApi,
         },
         [WorkspaceRoutes.FileUpload]: {
            moduleApi: fileUploadModuleApi,
         },
         [WorkspaceRoutes.ResetPassword]: {
            moduleApi: resetPasswordModuleApi,
         },
         [WorkspaceRoutes.UserProfile]: {
            moduleApi: userProfileModuleApi,
         },
         [WorkspaceRoutes.ShowSession]: {
            moduleApi: sessionModuleApi,
         },
         [WorkspaceRoutes.Unauthorized]: {
            moduleApi: unauthorizedModuleApi,
         },
      },
   }
}
